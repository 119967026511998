@import 'styles/theme.scss';

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-ms-input-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
  &:focus {
    @content;
  }
}

@mixin resetSearchInput {
  /* clears the ‘X’ from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

:global {
  @include resetSearchInput;

  @keyframes ekokartan-auto-fill {
  }
  @keyframes ekokartan-auto-fill-cancel {
  }
}

.root {
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
  width: 100%;

  &:disabled {
    color: rgba(map-get($palette, primaryDark), 0.5);
    cursor: default;
  }
}

.input {
  display: block;
  font: inherit;
  letter-spacing: 1px;
  color: currentColor;
  @include p(0.5, 0, 0.5);
  border: 0px;
  background: none;
  min-width: 0px;
  height: 44px;
  margin: 0px;
  // Reset for Safari
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */

  // Make the flex item shrink with Firefox
  width: 100%;
  // Fix IE11 width issue
  animation-name: ekokartan-auto-fill-cancel;
  animation-duration: 10ms;

  // Reset Firefox invalid required input style
  &:invalid {
    box-shadow: none;
  }

  &:-webkit-search-decoration {
    // Remove the padding when type=search.
    -webkit-appearance: none;
  }

  @include placeholder {
    color: currentColor;
    text-transform: initial;
  }

  &:disabled {
    opacity: 1; // Reset iOS opacity
    -webkit-text-fill-color: rgba(
      map-get($palette, primaryDark),
      0.5
    ); // Fix opacity Safari bug
  }

  &:-webkit-autofill {
    animation-duration: 5000s;
    animation-name: ekokartan-auto-fill;
  }
}

/* Styles applied to the `input` element if `type="search"`. */
.search {
  // Improve type search style.
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
