@import 'styles/theme.scss';

.temporalToggleRoot {
  position: relative;
  overflow: hidden;
  display: flex;

  &.vertical {
    justify-content: flex-end;
    align-items: flex-start;
    width: 132px;
    height: 264px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        map-get($palette, primary) 0%,
        rgba(63, 176, 199, 0) 35.94%,
        rgba(63, 176, 199, 0) 68.23%,
        map-get($palette, primary) 100%
      );
      pointer-events: none;
    }
  }

  &.horizontal {
    justify-content: flex-start;
    width: 100%;
    height: 40px;
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}

.verticalList {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-right: 10px;

  & > * {
    margin: 6px auto;
  }
}

.horizontalList {
  position: absolute;
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  height: 40px;
  touch-action: pan-x;

  & > * {
    margin: auto 6px;
  }
}

.activeIndicator {
  width: 4px;
  min-width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: map-get($palette, primaryDarkest);

  &.horizontal {
    margin: 0 auto;
  }

  &.vertical {
    margin: auto 0;
  }
}
