@import 'styles/theme.scss';

.toggleRoot {
  width: 44px;
  min-width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: map-get($palette, white);
  color: map-get($palette, primaryDark);
  box-shadow: 0px 4px 4px rgba(black, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
