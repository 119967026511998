@import 'styles/theme.scss';

.scrollableListRoot {
  max-width: 100%;
  overflow: hidden;
}

.visibleView {
  display: flex;
  width: 100%;
  height: calc(100% + 20px);
  padding-bottom: 20px;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

.innerRoot {
  height: 100%;
}
