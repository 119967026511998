@import 'styles/theme.scss';

.subheader {
  display: flex;
  align-items: center;
  padding: 16px 16px 8px;

  & > h6 {
    @include m(0);
  }

  @media (min-width: 768px) {
    padding: 16px;
    background-color: #51b4ca;
    position: sticky;
    top: 0px;
    z-index: 9;
  }
}
