@import 'styles/theme.scss';

.root {
  cursor: pointer;
  @include p(0, 2);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  height: 40px;
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.125);

  // NOTE: the following styles could be infered as a style variant if the project is to grow
  background-color: map-get($palette, white);
  color: map-get($palette, primaryDark);
}

.adornment {
  display: flex;
  align-items: center;

  &.start {
    @include mr(1);
  }

  &.end {
    @include ml(1);
  }
}
