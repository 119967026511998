@import 'styles/theme.scss';

.graphRoot {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 150px;
  overflow: hidden;
  touch-action: none;

  @include md {
    padding-bottom: 0px;
  }
}

.line {
  stroke: map-get($palette, graph);
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;

  &.defined {
    stroke: map-get($palette, graph);
    stroke-width: 1.2;
    stroke-dasharray: 6px 6px;
    opacity: 0.5;
  }
}

.area {
  fill: rgba(map-get($palette, graph), 0.2);
}

.axis,
.tick {
  stroke: map-get($palette, axis);
  stroke-width: 2px;

  &.background {
    stroke: rgba(map-get($palette, primary), 0.8);
    stroke-width: 54px;
    transform: translate(-26px, -2px);
  }

  &.indicator {
    display: none;
    stroke: rgba(map-get($palette, axis), 0.4);
    stroke-dasharray: 8px 8px;
  }

  @include md {
    &.indicator {
      display: block;
    }
  }
}

.tickY {
  .tick {
    stroke-width: 1px;
    opacity: 0.25;
  }
}

.legend {
  position: absolute;
  left: 0px;
  bottom: 32px;
  padding: 12px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  background-color: rgba(map-get($palette, primary), 0.75);

  @include xs {
    bottom: 48px;
  }

  @include md {
    left: 50%;
    transform: translateX(-50%);
    bottom: 76px;
  }
}

.legendItem {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 4px;
  margin-top: 2px;
  font-size: 12px;
  letter-spacing: 1.2px;
  font-weight: map-get($font-weight, medium);

  .activeLine {
    width: 16px;
    height: 2px;
    background-color: map-get($palette, graph);
    margin-right: 6px;
  }

  .referenceArea {
    width: 16px;
    height: 16px;
    background-color: rgba(map-get($palette, graph), 0.15);
    margin-right: 6px;
  }
}

.text {
  fill: map-get($palette, white);
  opacity: 0.9;
  font-size: 11px;

  &.textX {
    transform: translate(-30px, 6px) rotate(-45deg) !important;
    text-anchor: middle;
  }

  &.textY {
    transform: translate(-6px, 5px) !important;
    text-anchor: end;
  }

  &.unit {
    text-anchor: end;
    font-weight: map-get($font-weight, medium);
    transform: translate(0, -20px) !important;
  }
}

.svg {
  touch-action: none;
}
