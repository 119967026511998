@import 'styles/theme.scss';

.root {
  height: 100%;
  width: 100%;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: map-get($palette, primary);
  padding: 16px;
}

.panelHeader {
  font-weight: 700;
  font-size: 11px;
  text-align: right;
  opacity: 0.4;
}

.description {
  align-self: flex-start;
  padding-bottom: 16px;
  overflow-y: auto;
  margin: 0 0 auto;

  & p {
    margin-bottom: 16px;
  }
}

.iconButton {
  background-color: rgba(map-get($palette, primaryDarkest), 0.15) !important;
  width: 36px !important;
  min-width: 36px !important;
  height: 36px !important;

  &.open {
    transform: rotate(-180deg);
  }
}

.datasetList {
  display: flex;
  padding: 8px 0 16px 0;

  & > * {
    margin-right: 8px;
  }

  & > *:last-of-type {
    margin-right: 0;
  }
}

.footer {
  border-top: 1px solid rgba(map-get($palette, primaryDark), 0.5);
}

.listItemActive {
  background-color: rgba(map-get($palette, primaryDark), 1) !important;

  button {
    cursor: auto !important;
  }
}
