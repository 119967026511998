@import "./styles/theme.scss";

.root {
  display: block;
  flex: 1 1 auto;
  @include p(1, 0);
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 1.5;
}

.bold {
  font-weight: map-get($font-weight, bold);
  letter-spacing: 1.4px;
}
