@import 'styles/theme';

.root {
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}

.appbar {
  position: relative;
  width: 100%;
  display: flex;
  padding: 16px 10px;
}

.actionButton {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 16px;
  display: flex;
  justify-content: center;

  & > button > span {
    text-transform: capitalize;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonGroup {
  flex-shrink: 1;
  margin: 16px 0px 16px 10px;
  @media (min-width: 930px) {
    margin: 16px 10px;
  }
}

.headerRoot {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 8px;
  @media (min-width: 768px) {
    padding-bottom: 32px;
  }
}

.searchBar {
  z-index: 11;
  position: relative;

  @media (min-width: 768px) {
    margin: 16px 10px;
    max-width: 355px;
    min-width: 355px;
  }
}

.mainView {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: 768px) {
    padding-right: 300px;
  }

  @media (min-width: 1080px) {
    padding-right: 400px;
  }
}

.dataView {
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.temporalMenu {
  position: relative;
  display: flex;
  align-items: center;
}

.innerRoot {
  height: calc(100vh - 148px);
  height: calc((var(--vh, 1vh) * 100) - 148px);

  @media (min-width: 768px) {
    display: flex;
    flex-grow: 1;
    height: auto;
  }
}

.sidepanel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 300px;
  max-width: 300px;

  @media (min-width: 1080px) {
    min-width: 400px;
    max-width: 400px;
  }
}
