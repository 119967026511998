@import 'modern-css-reset';
@import './styles/theme';

/** Currently not used in theme */
@font-face {
  font-family: 'Botanica';
  src: url('./fonts/botanica.eot?#iefix');
  src: url('./fonts/botanica.eot?#iefix') format('eot'),
    url('./fonts/botanica.woff2') format('woff2'),
    url('./fonts/botanica.woff') format('woff'),
    url('./fonts/botanica.ttf') format('truetype'),
    url('./fonts/botanica.svg#26afa31e-4ca2-4cd7-a29d-722ca254a995')
      format('svg');
  font-display: swap;
}

/** Currently not used in theme */
@font-face {
  font-family: 'Futura';
  src: url('./fonts/futura-book.eot?#iefix');
  src: url('./fonts/futura-book.eot?#iefix') format('eot'),
    url('./fonts/futura-book.woff2') format('woff2'),
    url('./fonts/futura-book.woff') format('woff'),
    url('./fonts/futura-book.ttf') format('truetype'),
    url('./fonts/futura-book.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('./fonts/futura-medium.eot?#iefix');
  src: url('./fonts/futura-medium.eot?#iefix') format('eot'),
    url('./fonts/futura-medium.woff2') format('woff2'),
    url('./fonts/futura-medium.woff') format('woff'),
    url('./fonts/futura-medium.ttf') format('truetype'),
    url('./fonts/futura-medium.svg#c35c8eec-24d3-4ed7-9159-df80a008bf54')
      format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('./fonts/futura-bold.eot?#iefix');
  src: url('./fonts/futura-bold.eot?#iefix') format('eot'),
    url('./fonts/futura-bold.woff2') format('woff2'),
    url('./fonts/futura-bold.woff') format('woff'),
    url('./fonts/futura-bold.ttf') format('truetype'),
    url('./fonts/futura-bold.svg#20073de8-56dd-4e4c-9434-5097c3f2fd28')
      format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('./fonts/futura-heavy.eot?#iefix');
  src: url('./fonts/futura-heavy.eot?#iefix') format('eot'),
    url('./fonts/futura-heavy.woff2') format('woff2'),
    url('./fonts/futura-heavy.woff') format('woff'),
    url('./fonts/futura-heavy.ttf') format('truetype'),
    url('./fonts/futura-heavy.svg#33342cd1-a4fd-46e1-a810-aee3d1c24099')
      format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: map-get($palette, primary);
  color: map-get($palette, white);
  font-size: 14px;
  font-weight: map-get($font-weight, regular);
  font-family: 'Futura', 'Helvetica Neue', sans-serif;
  min-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Button overrides
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0;
  border: none;
  outline: none;
  color: inherit;
  background: inherit;
}

button {
  appearance: none;
  -webkit-tap-highlight-color: transparent;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:focus {
  outline: 0;
}

a {
  color: map-get($palette, primaryDark);
}
