@import "styles/theme.scss";

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 32px;
  width: 88px;
  background-color: rgba(white, 0.3);
  color: map-get($palette, primaryDark);
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  font-size: 18px;
  border-radius: 66px;
  cursor: pointer;

  &.active {
    background-color: map-get($palette, primaryDark);
    color: map-get($palette, white);
  }
}
