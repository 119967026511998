@import './styles/theme';

.root {
  width: 100%;

  @media (min-width: 768px) {
    margin: 0 auto;
    padding-top: 5rem;
    overflow-x: hidden;
  }

  @include lg {
    width: 90%;
    max-width: 960px;
  }
}

.listHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  padding-left: 48px;
  padding-right: calc(16px + 7.5px);
  min-height: 40px;
  & > button {
    background: transparent;
    color: #fff;
    box-shadow: none;
  }

  & > h6 {
    padding-left: 16px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0;
    text-transform: capitalize;
  }
}

.list {
  padding: 0px 0px 120px;
  max-height: calc(100vh - 188px);
  max-height: calc((var(--vh, 1vh) * 100) - 188px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  @media (min-width: 768px) {
    width: calc(100% + 20px);
    height: 100%;
    max-height: calc(100vh - 116px - 5rem);
    max-height: calc((var(--vh, 1vh) * 100) - 116px - 5rem);
    padding: 0px 20px 32px 0;
    overflow-y: auto;
  }
}

.filterButton {
  background-color: rgba(map-get($palette, primaryDarkest), 0.07) !important;
  padding-right: 8px;
  height: 36px !important;
}

.listItem {
  pointer-events: none;
  @media (min-width: 768px) {
    & > div {
      justify-content: flex-start;
    }
  }
}

.listItemInner {
  font-size: 16px;
  margin-right: 12px;
  width: 100%;
  min-width: 175px;
  display: inline-flex;

  & > span:first-of-type {
    display: inline-block;
    width: 48px;
    min-width: 48px;
    font-weight: 500;
    opacity: 0.6;
  }

  @include md {
    width: 175px;
    min-width: 175px;
  }

  @include lg {
    width: 200px;
    min-width: 200px;
  }
}

.menuDialog {
  & button {
    font-weight: 700;
    min-width: 160px;
    padding: 16px 16px;
  }
}

.active {
  background-color: map-get($palette, primaryDark);
}

.highlighted {
  background-color: map-get($palette, primaryLight);
}

.barRoot {
  @media (min-width: 768px) {
    position: relative;
    width: 100%;
  }
}
