@import './styles/theme';

.bar {
  min-width: 30px;
  width: 100%;
  max-width: 30px;
  height: 30px;
  border-radius: 45px;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: map-get($font-weight, medium);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0 0;

  &.noData {
    background-color: rgba(map-get($palette, white), 0.2);
    color: map-get($palette, primaryDark);
    letter-spacing: 0px;
  }

  &.red {
    background-color: map-get($palette, red);
    color: map-get($palette, white);
  }

  &.orange {
    background-color: map-get($palette, orange);
    color: map-get($palette, white);
  }

  &.yellow {
    background-color: map-get($palette, yellow);
    color: map-get($palette, primaryDark);
  }

  &.green {
    background-color: map-get($palette, green);
    color: map-get($palette, white);
  }

  &.darkGreen {
    background-color: map-get($palette, darkGreen);
    color: map-get($palette, white);
  }

  @media (min-width: 768px) {
    & {
      max-width: unset;
      justify-content: flex-end;
      padding-right: 8px;
    }

    &.noData {
      padding-right: 5px;
    }

    &.condensed {
      max-width: 30px;
      margin: 0 auto;
      padding-right: 0;
      justify-content: center;
    }
  }
}
