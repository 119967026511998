@import 'styles/theme.scss';

.modalRoot {
  height: 100%;
  width: 100%;
  background-color: map-get($palette, primaryLight);
  padding-top: 68px;
  position: relative;
  z-index: 11;

  & ul {
    overflow-y: scroll;
    max-height: calc(100vh - 106px);
    max-height: calc((var(--vh, 1vh) * 100) - 106px);
    padding-bottom: 54px;
  }
}
