@import 'styles/theme.scss';

.root {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 6px;
  outline: 0;
  background: #61b8cc;
  box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
