@import 'styles/theme.scss';

.mapRoot {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.g {
  transition: transform 0.3s ease-in-out;
  transform: translate(var(--translateX), var(--translateY)) scale(var(--scale));
}

.legend {
  position: absolute;
  right: 0px;
  bottom: 48px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  background-color: rgba(map-get($palette, primary), 0.75);

  @include md {
    right: 24px;
    bottom: 64px;
  }
}

.legendItem {
  width: 100%;
  flex: 1;
  padding: 0 4px;
  margin-top: 2px;
  font-size: 12px;
  letter-spacing: 1.2px;
  font-weight: map-get($font-weight, medium);

  &.noData {
    border-left: 8px solid rgba(map-get($palette, white), 0.2);
  }

  &.red {
    border-left: 8px solid map-get($palette, red);
  }

  &.orange {
    border-left: 8px solid map-get($palette, orange);
  }

  &.yellow {
    border-left: 8px solid map-get($palette, yellow);
  }

  &.green {
    border-left: 8px solid map-get($palette, green);
  }

  &.darkGreen {
    border-left: 8px solid map-get($palette, darkGreen);
  }
}

.geoShape {
  opacity: 0.1;
  cursor: pointer;
  transition: fill 0.2s ease-in;

  &.highlight {
    opacity: 1;
  }

  &.noData {
    fill: rgba(map-get($palette, white), 0.05);

    &.highlight {
      fill: rgba(map-get($palette, white), 0.2);

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.red {
    fill: map-get($palette, red);
  }

  &.orange {
    fill: map-get($palette, orange);
  }

  &.yellow {
    fill: map-get($palette, yellow);
  }

  &.green {
    fill: map-get($palette, green);
  }

  &.darkGreen {
    fill: map-get($palette, darkGreen);
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
