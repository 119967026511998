@import 'styles/theme.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: map-get($palette, primaryDark);
  background: map-get($palette, white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 44px;
  border-radius: 66px;

  @include p(0, 2);

  &.disabled {
    opacity: 0.5;
    box-shadow: unset;
    cursor: disabled;
  }
}

.searchIcon {
  @include mr(1);
}
