@import 'styles/theme';

@mixin hoverEffect {
  @media (hover: hover) {
    &:hover,
    &:focus {
      @content;
    }
  }
}

.root {
  display: flex;
  align-items: flex-end;
  position: relative;
  min-width: 132px;
  width: 100%;
  height: 94px;
  background-color: map-get($palette, primaryDark);
  border-radius: 4px;
  box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.2);
  padding: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .cardHeader {
    z-index: 10;
    width: 100%;
    height: auto;
    word-wrap: break-word;
    color: map-get($palette, white);
    font-size: 14px;
    font-weight: map-get($font-weight, medium);
    letter-spacing: 1px;
    line-height: 18px;
    margin: 0;
    align-self: flex-end;
    padding-bottom: 8px;
    &.active {
      font-weight: map-get($font-weight, bold);
    }
  }
}

.svgContainer {
  position: absolute;
  align-self: center;
}

.overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  position: absolute;
  background-color: map-get($palette, primary);
  opacity: 0.4;
}

.menuDialog {
  padding: 8px !important;
}
