@import 'styles/theme.scss';

.root {
  display: flex;
  flex-direction: column;

  & button {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-right: 1px solid rgba(map-get($palette, primaryDark), 0.1);
  }

  @media (min-width: 930px) {
    flex-direction: row;

    & button {
      border-radius: 0;
    }

    & button:first-child {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      border-right: 1px solid rgba(map-get($palette, primaryDark), 0.1);
    }

    & button:last-child {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      border-left: 1px solid rgba(map-get($palette, primaryDark), 0.1);
    }
  }
}

.button {
  border-radius: 0;
  box-shadow: none;
  background-color: rgba(white, 0.3);
  color: map-get($palette, primaryDark);
  transition: all 0.3s ease-out;
}

.active {
  background-color: map-get($palette, primaryDark);
  color: map-get($palette, white);
}
