// specify whether !important is applied
$spacing-important: false;

// reset spacing to be a fixed base across all breakpoints
$base-spacing: 8px;
$spacing-sizes: (
  0: 0px,
  0.5: $base-spacing * 0.5,
  1: $base-spacing * 1,
  2: $base-spacing * 2,
  3: $base-spacing * 3,
  4: $base-spacing * 4,
  5: $base-spacing * 5,
  6: $base-spacing * 6,
);
$modal-z-index: 10;
$backdrop-z-index: 9;

@import 'sass-spacing/mixins.scss';

$palette: (
  primary: #36adc5,
  primaryLight: #61b8cc,
  primaryDark: #0c687a,
  primaryDarkest: #06323b,
  secondary: #dbd37e,
  secondaryDark: #5c5935,
  error: #db031b,
  errorDark: #5c010c,
  success: #8fdc76,
  successDark: #3c5c32,
  warning: #f29e1f,
  yellow: #f6de4a,
  orange: #e69836,
  green: #6ba83e,
  darkGreen: #2c653e,
  red: #c92b29,
  white: #ffffff,
  axis: #81c3d2,
  graph: #6a213e,
);

$gradients: (
  primary:
    linear-gradient(
      114.44deg,
      map-get($palette, primary) 0%,
      map-get($palette, primaryLight) 100%
    ),
);

$font-weight: (
  regular: 400,
  medium: 500,
  bold: 700,
);

$breakpoints: (
  xs: 365px,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px,
);

//breakpoint mixins
@mixin xs {
  @media screen and (min-width: map-get($breakpoints, xs)) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: map-get($breakpoints, sm)) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: map-get($breakpoints, md)) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: map-get($breakpoints, lg)) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: map-get($breakpoints, xl)) {
    @content;
  }
}
