@import 'styles/theme.scss';

.root {
  overflow: hidden;
  position: fixed;
  max-height: 90vh;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: map-get($palette, primaryLight);
  z-index: 1000;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal {
  z-index: 20;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  height: 58px;
  font-size: 14px;
  letter-spacing: 1px;
  width: 100%;
  font-weight: map-get($font-weight, medium);

  & > h6 {
    font-size: 14px;
  }
}
