@import 'styles/theme.scss';

.root {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.description {
  position: relative;
  padding: 0 16px;
  overflow-y: auto;
  max-height: 174px;

  & p {
    margin-bottom: 16px;
  }
}

.datasetList {
  position: relative;
  display: flex;
  margin-top: 8px;
  padding: 8px 16px;

  & > * {
    margin-right: 8px;
  }

  & > *:last-of-type {
    margin-right: 0;
  }
}

.partnershipRoot {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 8px 16px;
}
