@import 'styles/theme.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 44px;
  width: 44px;
  min-width: 44px;
  border-radius: 50%;
  background-color: transparent;
  transition: all 0.3s ease-out;

  &:hover {
    background-color: rgba(map-get($palette, primaryDark), 0.1);
    // Reset on touch devices, it doesn't add specificity
    @media (hover: none) {
      background-color: transparent;
    }
  }
}

.edgeEnd {
  margin-right: -8px;
}

.edgestart {
  margin-left: -8px;
}
