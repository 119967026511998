@import 'styles/theme.scss';

.root {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: inherit;

  & button {
    justify-content: flex-start;
    font-weight: map-get($font-weight, bold);
    font-size: 16px;
    letter-spacing: 1px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  height: 50px;
  font-size: 14px;
  letter-spacing: 1px;
  width: 100%;
  font-weight: 500;

  & > h6 {
    font-size: 14px;
  }
}

.active {
  background-color: map-get($palette, primaryDark);
}
