@import 'styles/theme.scss';

// Sets the style only for pseudo selectors
@mixin setPseudoSelectors {
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:active {
    @content;
  }
}

.root {
  position: relative;
  margin-bottom: 0px;
  width: 100%;
  list-style: none;
}

.showListStyles {
  list-style: unset;
}

.childrenRoot {
  width: 100%;
  cursor: inherit;
  @include p(1, 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  text-decoration: none;
  transition: all 0.3s ease-out;

  @include setPseudoSelectors {
    color: #fff;
    background-color: map-get($palette, primaryDark);
    -webkit-tap-highlight-color: rgba(map-get($palette, primaryDark), 0.4);
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.startIcon {
  @include mr(2);
  margin-left: -8px;
}

.endIcon {
  @include ml(2);
  margin-right: -8px;
}

.large {
  @include py(3);
}

.condensed {
  @include py(0);
}

.button {
  cursor: pointer;

  &:hover {
    background-color: map-get($palette, primaryDark);
    // Reset on touch devices, it doesn't add specificity
    @media (hover: none) {
      background-color: transparent;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: rgba(map-get($palette, primaryDark), 0.2);
      // Reset on touch devices, it doesn't add specificity
      @media (hover: none) {
        background-color: transparent;
      }
    }
  }
}

.underline {
  border-bottom: 1px solid rgba(map-get($palette, primaryDark), 0.5);
}
