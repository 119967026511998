@import 'styles/theme.scss';

.root {
  margin: 0px;
  width: 100%;
  list-style: none;
  padding: 0px;
}

.padding {
  @include py(1);
}

.subheader {
  @include pt(0);
}
