@import 'styles/theme.scss';

.tooltip {
  display: none;
  position: fixed;
  padding: 8px;
  background-color: rgba(map-get($palette, primary), 0.75);
  border-radius: 18px;
  pointer-events: none;
  align-items: center;

  p {
    margin: 0 8px;
    font-weight: 700;
  }

  @include md {
    display: flex;
  }
}
